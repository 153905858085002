import { pages } from "../../constants/PagePaths";
import { Link, useHistory } from "react-router-dom";
import { api } from "../../config/api";
import { util } from "../../config/util";
import { Cookies } from "../../constants/Cookies";

export default (props: { userName: string }) => {
  const history = useHistory();

  const clearUserInfo = () => {
    try {
      api.logout();
      history.push(pages.index);
    } catch (err) {}
    util.deleteCookie(Cookies.authKey);
    window.location.reload();
  };

  return (
    <header>
      <h3 className="text-dark">
        반갑습니다,
        <br />
        <div className="avatar-name">
          <span className="avatar-img">
            <img src="/images/ddusul/icon/level3.png" />
          </span>
          <span>{props.userName || ""}</span>
        </div>
        님!
      </h3>
      <div className="d-flex align-items-center gap-2">
        <Link to={pages.mypage} className="btn btn-tn btn-primary-soft">
          내 이용권
        </Link>
        <Link to={pages.changepassword} className="btn btn-tn btn-outline">
          비밀번호 변경
        </Link>
      </div>
      <div className="mypage-menu">
        <Link
          to={pages.mypage + "/video"}
          className="btn btn-lg btn-white pe-2 "
        >
          <div className="d-flex align-items-center gap-2">
            <span className="material-symbols-outlined">school</span>
            나의 강의장
          </div>
          <span className="material-symbols-outlined lg-view">
            chevron_right
          </span>
        </Link>
        {/* <Link to={pages.coupon} className="btn btn-lg btn-white pe-2 ">
          <div className="d-flex align-items-center gap-2">
            <span className="material-symbols-outlined">
              confirmation_number
            </span>
            쿠폰
          </div>
          <span className="material-symbols-outlined lg-view">
            chevron_right
          </span>
        </Link> */}
        <Link
          to={pages.mypage + "/payment"}
          className="btn btn-lg btn-white pe-2 "
        >
          <div className="d-flex align-items-center gap-2">
            <span className="material-symbols-outlined">sort</span>
            거래내역
          </div>
          <span className="material-symbols-outlined lg-view">
            chevron_right
          </span>
        </Link>
        <Link
          to={pages.mypage + "/contact"}
          className="btn btn-lg btn-white pe-2 "
        >
          <div className="d-flex align-items-center gap-2">
            <span className="material-symbols-outlined">quiz</span>
            나의 용역
          </div>
          <span className="material-symbols-outlined lg-view">
            chevron_right
          </span>
        </Link>
        <Link to={pages.mypage + "/qna"} className="btn btn-lg btn-white pe-2 ">
          <div className="d-flex align-items-center gap-2">
            <span className="material-symbols-outlined">quiz</span>
            나의 질문과 답변
          </div>
          <span className="material-symbols-outlined lg-view">
            chevron_right
          </span>
        </Link>
      </div>
      <a
        className="btn btn-tn text-gray-400 lg-view"
        onClick={() => {
          clearUserInfo();
        }}
      >
        로그아웃
      </a>
      <Link to={pages.withdrawal} className="btn btn-tn text-gray-200 lg-view">
        회원탈퇴
      </Link>
    </header>
  );
};
