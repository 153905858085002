import { Link, useHistory, useLocation } from "react-router-dom";
import { pages } from "../constants/PagePaths";
import React from "react";

export default (props: { currentEmail?: string; unreadCount: number }) => {
  const location = useLocation();
  const linkLocation = location.pathname;
  const history = useHistory();

  return (
    <header
      className="bg-white sticky-top  shadow-sm"
      style={
        linkLocation.indexOf("video/course") > -1 ? { display: "none" } : {}
      }
    >
      <div className="container">
        <div className="header-wrap">
          <h1 className="d-flex align-items-center mb-0 col-sm-4 col-5">
            <Link to={pages.index} className="d-flex">
              <img src="/images/ddusul/logo.png" className="header-logo" />
            </Link>
          </h1>
          <div className="col-4 sm-view text-end">
            <button
              className="btn btn-lg pe-0 text-dark"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <span className="material-symbols-outlined">segment</span>
            </button>
            {props.currentEmail && (
              <Link to={pages.mypage}>
                <button className="btn btn-lg pe-0 text-dark" type="button">
                  <span className="material-symbols-outlined">
                    account_circle
                  </span>
                </button>
              </Link>
            )}
          </div>
          {props.currentEmail ? (
            <ul
              className="nav nav-pills align-items-center lg-view"
              id="login-after"
            >
              <li className="nav-item">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    history.push(pages.mypage + "/video");
                  }}
                >
                  내 강의장
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="btn btn-tn btn-link-dark position-relative"
                  data-bs-toggle="modal"
                  data-bs-target="#alarmModal"
                >
                  {props.unreadCount != 0 ?? (
                    <span className="position-absolute new-position translate-middle p-1 bg-primary border border-light rounded-circle">
                      <span className="visually-hidden">New alerts</span>
                    </span>
                  )}
                  <span className="material-symbols-outlined">
                    notifications
                  </span>
                </button>
                <Link to={pages.mypage} className="btn btn-tn btn-link-dark">
                  <span className="material-symbols-outlined">
                    account_circle
                  </span>
                </Link>
              </li>
            </ul>
          ) : (
            <ul className="nav nav-pills align-items-center lg-view">
              <li className="nav-item">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    history.push(pages.signup);
                  }}
                >
                  회원가입
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="btn btn-link-dark"
                  onClick={() => {
                    history.push(pages.login);
                  }}
                >
                  로그인
                </button>
              </li>
            </ul>
          )}
        </div>

        <nav
          className="navbar navbar-expand-sm navbar-light"
          aria-label="Third navbar"
          id="header-nav"
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarsExample03"
              aria-controls="navbarsExample03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarsExample03">
              <ul className="navbar-nav me-auto mb-2 mb-sm-0">
                <li className="nav-item">
                  <div className="ac">
                    <button className="nav-link btn  px-0">
                      <span className="material-symbols-outlined">menu</span>
                      전체 카테고리
                      <span className="material-symbols-outlined">
                        arrow_drop_down
                      </span>
                    </button>
                    <div className="all-category-section">
                      <div>
                        <div className="container">
                          <div className="all-category-wrap">
                            <div className="all-category">
                              <div className="SubTitle3 text-dark">
                                업무 자동화 프로그램
                              </div>
                              <ul>
                                <li>
                                  <Link to={pages.costprojects + "/normal"}>
                                    설계내역
                                    <span className="badge text-bg-primary ms-2">
                                      추천
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to={pages.costprojects + "/copy"}>
                                    입찰내역
                                  </Link>
                                </li>
                                <li>
                                  <Link to={pages.costprojects + "/estimate"}>
                                    견적서
                                  </Link>
                                </li>
                                <li>
                                  <Link to={pages.excel}>엑셀 자동화</Link>
                                </li>
                              </ul>
                            </div>
                            <div className="all-category">
                              <div className="SubTitle3 text-dark">동영상</div>
                              <ul>
                                <li>
                                  <Link to={pages.video}>동영상 강의</Link>
                                </li>
                                <li>
                                  <Link to={pages.lectureroom + "/0"}>
                                    사용법 강의
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="all-category">
                              <div className="SubTitle3 text-dark">
                                업무지원
                              </div>
                              <ul>
                                <li>
                                  <Link to={pages.contact}>설계 용역</Link>
                                </li>
                                <li>
                                  <Link to={pages.data}>서류 자료실</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown submenu text-center">
                  <Link
                    className="nav-link dropdown-toggle"
                    to={pages.costprojects + "/normal"}
                  >
                    서비스
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        to={pages.costprojects + "/normal"}
                        className="dropdown-item"
                      >
                        설계내역
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={pages.costprojects + "/copy"}
                        className="dropdown-item"
                      >
                        입찰내역
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={pages.costprojects + "/estimate"}
                        className="dropdown-item"
                      >
                        견적서
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={pages.costprojects + "/worklist"}
                        className="dropdown-item"
                      >
                        계약서류
                      </Link>
                    </li>
                    <li>
                      <Link to={pages.excel} className="dropdown-item">
                        엑셀 자동화
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item text-center">
                  <Link to={pages.lectureroom + "/0"} className="nav-link">
                    사용법
                  </Link>
                </li>
                <li className="nav-item text-center">
                  <Link to={pages.video} className="nav-link">
                    강의실
                  </Link>
                </li>
                <li className="nav-item text-center">
                  <Link to={pages.payment} className="nav-link">
                    이용권
                  </Link>
                </li>
                <li className="nav-item dropdown submenu text-center">
                  <Link to={pages.contact} className="nav-link dropdown-toggle">
                    용역 의뢰
                  </Link>
                </li>
                <li className="nav-item dropdown submenu text-center">
                  <Link
                    to={pages.noticelist}
                    className="nav-link dropdown-toggle"
                  >
                    고객센터
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to={pages.data} className="dropdown-item">
                        자료실
                      </Link>
                    </li>
                    <li>
                      <Link to={pages.noticelist} className="dropdown-item">
                        공지사항
                      </Link>
                    </li>
                    <li>
                      <Link to={pages.inquiry} className="dropdown-item">
                        문의하기
                      </Link>
                    </li>
                    <li>
                      <Link to={pages.statementlist} className="dropdown-item">
                        샘플내역서
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="mobile-nav">
          <div
            className="offcanvas offcanvas-end"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              {!props.currentEmail && (
                <div className="col-6 d-flex gap-2">
                  <Link to={pages.signup}>
                    <button
                      className="btn btn-primary"
                      data-bs-dismiss="offcanvas"
                    >
                      회원가입
                    </button>
                  </Link>
                  <Link to={pages.login}>
                    <button
                      className="btn btn-link-dark"
                      data-bs-dismiss="offcanvas"
                    >
                      로그인
                    </button>
                  </Link>
                </div>
              )}

              {props.currentEmail && (
                <div className="col-6 d-flex gap-2" id="login-after">
                  <button
                    className="btn btn-primary"
                    data-bs-dismiss="offcanvas"
                    onClick={() => {
                      history.push(pages.mypage + "/video");
                    }}
                  >
                    내 강의장
                  </button>
                  <button
                    className="btn btn-tn btn-link-dark position-relative"
                    data-bs-toggle="modal"
                    data-bs-target="#alarmModal"
                  >
                    <span className="position-absolute new-position translate-middle p-1 bg-primary border border-light rounded-circle">
                      <span className="visually-hidden">New alerts</span>
                    </span>
                    <span className="material-symbols-outlined">
                      notifications
                    </span>
                  </button>
                </div>
              )}

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body" id="mobile-menu-nav">
              <div className="nav-category-menu">
                <a
                  onClick={() => history.push(pages.costprojects + "/normal")}
                  data-bs-dismiss="offcanvas"
                  // to={pages.costprojects + "/normal"}
                  className="nav-category-btn pointer"
                >
                  <img src="/images/ddusul/icon/01.png" />
                  설계 내역
                </a>
                <a
                  className="nav-category-btn"
                  data-bs-dismiss="offcanvas"
                  onClick={() => history.push(pages.contact)}
                >
                  <img src="/images/ddusul/icon/02.png" />
                  용역
                </a>
                <a
                  onClick={() => history.push(pages.excel)}
                  data-bs-dismiss="offcanvas"
                  className="nav-category-btn"
                >
                  <img src="/images/ddusul/icon/03.png" />
                  엑셀 자동화
                </a>
                <a
                  onClick={() => history.push(pages.data)}
                  data-bs-dismiss="offcanvas"
                  className="nav-category-btn"
                >
                  <img src="/images/ddusul/icon/04.png" />
                  서류 자료실
                </a>
                <a
                  onClick={() => history.push(pages.video)}
                  data-bs-dismiss="offcanvas"
                  className="nav-category-btn"
                >
                  <img src="/images/ddusul/icon/05.png" />
                  동영상 강의
                </a>
              </div>

              <div className="accordion accordion-flush" id="mobile-menu">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      서비스
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#mobile-menu"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          <Link
                            to={pages.costprojects + "/normal"}
                            data-bs-dismiss="offcanvas"
                          >
                            설계내역
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={pages.costprojects + "/copy"}
                            data-bs-dismiss="offcanvas"
                          >
                            입찰내역
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={pages.costprojects + "/estimate"}
                            data-bs-dismiss="offcanvas"
                          >
                            견적서
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={pages.costprojects + "/worklist"}
                            data-bs-dismiss="offcanvas"
                          >
                            계약서류
                          </Link>
                        </li>
                        <li>
                          <Link to={pages.excel}>엑셀 자동화</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <Link to={pages.lectureroom}>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-heading1">
                      <button
                        className="accordion-button default"
                        data-bs-dismiss="offcanvas"
                        type="button"
                      >
                        사용법
                      </button>
                    </h2>
                  </div>
                </Link>
                <Link to={pages.video}>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-heading2">
                      <button
                        className="accordion-button default"
                        type="button"
                        data-bs-dismiss="offcanvas"
                      >
                        강의실
                      </button>
                    </h2>
                  </div>
                </Link>
                <Link to={pages.payment}>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingfour">
                      <button
                        className="accordion-button default"
                        type="button"
                        data-bs-dismiss="offcanvas"
                      >
                        이용권
                      </button>
                    </h2>
                  </div>
                </Link>
                <Link to={pages.contact}>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingsix">
                      <button
                        className="accordion-button default"
                        type="button"
                        data-bs-dismiss="offcanvas"
                      >
                        용역 의뢰
                      </button>
                    </h2>
                  </div>
                </Link>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingfive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapsefive"
                      aria-expanded="false"
                      aria-controls="flush-collapsefive"
                    >
                      고객센터
                    </button>
                  </h2>
                  <div
                    id="flush-collapsefive"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingfive"
                    data-bs-parent="#mobile-menu"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li data-bs-dismiss="offcanvas">
                          <Link to={pages.data}>자료실</Link>
                        </li>
                        <li data-bs-dismiss="offcanvas">
                          <Link to={pages.noticelist}>공지사항</Link>
                        </li>
                        <li data-bs-dismiss="offcanvas">
                          <Link to={pages.inquiry}>문의하기</Link>
                        </li>
                        <li data-bs-dismiss="offcanvas">
                          <Link to={pages.statementlist}>샘플내역서</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
