/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { api } from "../../config/api";
import { pages } from "../../constants/PagePaths";
import "./myPage.scss";
import AlertContext from "../../contexts/AlertContext";
import PaymentHistoryModal from "../../components/paymentHistory/PaymentHistoryModal";
import {
  HistoryPoductModel,
  PaymentHistoryModel,
} from "../../models/payment/PaymentHistoryModel";
import CouponBoxModel from "../../models/coupon/CouponBoxModel";
import {
  IamportPayMethod,
  payMethodMapper,
} from "../../models/payment/iamport/ImpModels";
import { ExpiryDateModel } from "../../models/user/UserInfo";
import { LeftOutlined, RightOutlined, UserOutlined } from "@ant-design/icons";
import CountUp from "react-countup";
import {
  UserPermission,
  UserPermissionKorMapper,
} from "../../models/user/UserPermission";
import PaymentHistory from "../../components/paymentHistory/PaymentHistory";
import RegisterCouponModal from "../../components/modal/coupon/RegisterCouponModal";
import CouponList from "../../components/coupon/CouponList";
import CouponListModal from "../../components/modal/coupon/CouponListModal";
import MyPageHeader from "./MyPageHeader";
import { util } from "../../config/util";
import { Cookies } from "../../constants/Cookies";

interface Props {}

export default () => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [coupons, setCoupons] = useState<CouponBoxModel[]>([]);
  const [expires, setExpires] = useState<ExpiryDateModel[]>([]);
  const [expireCodes, setExpireCodes] = useState<string[]>([]);
  const [paymentHistory, setPaymentHistory] = useState<PaymentHistoryModel[]>(
    []
  );
  const [historyProducts, setHistoryProducts] = useState<
    HistoryPoductModel[][]
  >([]);
  const alert = useContext(AlertContext);
  const history = useHistory();
  const [selectedExpireIndex, setSelectedExpireIndex] = useState(0);

  const clearUserInfo = () => {
    try {
      api.logout();
      history.push(pages.index);
    } catch (err) {}
    util.deleteCookie(Cookies.authKey);
    window.location.reload();
  };

  useEffect(() => {
    new Promise(async () => {
      try {
        const res = await api.getMyInfo();
        setUserName(res.data.last_name);
        setEmail(res.data.email);
        setPhone(res.data.phone);
        setAddress(res.data.addr);

        const expires = res.data?.expiry_dates?.map((expireModel) => {
          const date = expireModel.expiry_date
            ? new Date(expireModel.expiry_date)
            : undefined;
          return {
            expiry_date: date,
            name: expireModel.name,
            permission_image: expireModel.permission_image,
            permission_title: expireModel.permission_title,
          };
        });
        setExpireCodes(
          res.data?.expiry_dates?.map((expireModel) =>
            expireModel.name.toLocaleLowerCase()
          )
        );

        setExpires(expires);
      } catch (error) {
        console.error(error);
        history.replace(pages.index);
      }

      try {
        const payHistory = await api.getPaymentHistoryAsync(0, 5);
        const { products, paymentHistories } = getHistories(payHistory);
        setHistoryProducts(products);
        setPaymentHistory(paymentHistories);
        const coupons = await getCoupons(0, 5);
        setCoupons(coupons);
      } catch (error) {
        console.error(error);
      }
    });
  }, []);

  const getCoupons = async (offset?: number, limit?: number) => {
    const couponsRes = await api.getCouponHistoryAsync(offset, limit);
    const tempCoupons = couponsRes.data.coupons.map(
      (coupon: any): CouponBoxModel => {
        return {
          name: coupon.name,
          expiryDate: coupon.expiry_date,
          key: coupon.key,
        };
      }
    );
    return tempCoupons;
  };

  const handleCouponMore = async () => {
    const coupons = await getCoupons();
    alert.showContentModal(
      <CouponListModal
        coupons={coupons}
        onConfirm={() => alert.closeAlert()}
      />,
      {
        width: "1200px",
      }
    );
  };

  const handlePayHistoryMore = async () => {
    const payHistory = await api.getPaymentHistoryAsync();
    const { products, paymentHistories } = getHistories(payHistory);
    alert.showContentModal(
      <PaymentHistoryModal
        histories={paymentHistories}
        handleClickVbankInfo={handleClickVbankInfo}
        historyProducts={products}
        onConfirm={() => alert.closeAlert()}
      />,
      {
        width: "1200px",
      }
    );
  };

  const handleClickVbankInfo = (index: number) => {
    const vbankDate = +(paymentHistory?.[index]?.vbankDate ?? "0");
    const date = new Date(vbankDate * 1000);
    alert.showAlert(
      `입금 기한 : ${date.format("yyyy.MM.dd HH:mm")}까지\n입금 계좌 : ${
        paymentHistory?.[index]?.vbankName
      } ${paymentHistory?.[index]?.vbankNum}\n계좌명 : ${
        paymentHistory?.[index]?.vbankHolder
      }`
    );
  };

  const getRemainDays = (expire: ExpiryDateModel | undefined) => {
    const currentTime = new Date().getTime();
    const expireDate = expire?.expiry_date?.getTime() ?? currentTime;
    return Math.max(
      Math.ceil((expireDate - currentTime) / (1000 * 3600 * 24)) +
        (expireDate - currentTime > 0 ? 1 : 0),
      0
    );
  };

  const getHistories = (payHistory: any) => {
    const products = payHistory.data.products.map((products: any) => {
      return products.map((prod: any) => {
        return {
          id: prod.id,
          paymentHistoryId: prod.payment_history_id,
          productId: prod.product_id,
          productPrice: prod.product_price,
          productName: prod.product_name,
          startDate: new Date(prod.start_date),
          expireDate: new Date(prod.expire_date),
          productDiscount: prod.product_discount,
        };
      });
    });
    const paymentHistories = payHistory.data.histories.map(
      (historyItem: any): PaymentHistoryModel => {
        const payMethod =
          Object.values(IamportPayMethod).find(
            (value) => value === historyItem.pay_method
          ) || IamportPayMethod.card;
        // milliseconds to seconds. cause its not unix Epoch value
        const paidAt = new Date(historyItem.paid_at * 1000)
          .format("yyyy.MM.dd")
          .trim();
        const expireDate = new Date(historyItem.expire_date)
          .format("yyyy.MM.dd")
          .trim();
        const startDate = new Date(historyItem.start_date)
          .format("yyyy.MM.dd")
          .trim();
        const status =
          historyItem.paid_at == undefined || historyItem.paid_at === 0
            ? new Date(historyItem.vbank_date).getTime() < Date.now()
              ? "만료"
              : "대기"
            : historyItem.canceled_time
            ? "취소"
            : "완료";
        return {
          amount: historyItem.paid_amount?.toLocaleString() + "원",
          payMethod: payMethodMapper[payMethod],
          prodName: historyItem.product_name
            ? `[${historyItem.product_name}]`
            : "",
          receiptUrl: historyItem.receipt_url,
          payRegistDate: paidAt,
          payRequestDate: new Date(historyItem.create_time).format(
            "yyyy.MM.dd"
          ),
          payTerm: startDate && expireDate ? `${startDate}~${expireDate}` : "",
          status,
          vbankHolder: historyItem.vbank_holder,
          vbankName: historyItem.vbank_name,
          vbankNum: historyItem.vbank_num,
          vbankDate: historyItem.vbank_date,
        };
      }
    );
    return { products, paymentHistories };
  };

  return (
    <section className="content">
      <div className="round-box">
        <div className="list-style-one">
          <ul className="list-group">
            <li className="list-group-item">
              <div className="title" style={{ fontSize: 14 }}>
                이메일
              </div>
              <div className="body">{email || ""}</div>
            </li>
            <li className="list-group-item">
              <div className="title" style={{ fontSize: 14 }}>
                연락처
              </div>
              <div className="body">{phone || ""}</div>
            </li>
            <li className="list-group-item">
              <div className="title" style={{ fontSize: 14 }}>
                주소
              </div>
              <div className="body">{address || ""}</div>
            </li>
          </ul>
        </div>
      </div>
      <button
        onClick={clearUserInfo}
        type="button"
        className="btn text-gray-300 sm-view m-right "
      >
        로그아웃
      </button>
      <button type="button" className="btn text-gray-300 sm-view m-right ">
        <Link to={pages.withdrawal} className="text-gray-200">
          회원탈퇴
        </Link>
      </button>
      <div className="d-flex justify-content-between align-items-center mt-8 mb-4">
        <h4 className="text-dark mb-0">사용중인 이용권</h4>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={() => {
            history.push(pages.payment);
          }}
        >
          추가 구매하기
        </button>
      </div>
      <div
        className="payment-list aos-init aos-animate flex-wrap"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        {expires
          .filter((exp) => exp.expiry_date)
          .map((expire, index) => {
            return (
              <div className="payment-list-item bg-white mb-5">
                <div className="img-wrap">
                  <img src={expire.permission_image} />
                </div>
                <div className="flex-2">
                  <div className="title-lg">{expire.permission_title}</div>
                  <div className="list-style-sm">
                    <ul className="list-group">
                      <li className="list-group-item">
                        <div className="title pe-3">이용기간</div>
                        <div className="body">
                          {expire?.expiry_date?.format("yyyy.MM.dd")}
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="title pe-3">잔여일수</div>
                        <div className="body">
                          <span className="text-danger">
                            {getRemainDays(expire)}
                          </span>
                          일
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
};
