import React, { createRef } from "react";
import DaumPostModal from "../../../../components/modal/daumPost/DaumPostModal";
import styles from "./VideoSignUp.module.scss";
import { Checkbox, Radio } from "@material-ui/core";
import SimpleDatePicker from "../../../../components/simpleDatePicker/SimpleDatePicker";
import { api } from "../../../../config/api";
import { util } from "../../../../config/util";
import { Cookies } from "../../../../constants/Cookies";
import { FreeUseDay } from "../../../../constants/General";
import { emailRegex } from "../../../../constants/RegexData";
import { privacyPolicy, termsOfService } from "../../../../constants/Terms";
import { BusinessApiResponseModel } from "../../../../models/apiModel/apiResponseModel";
import { Imp } from "../../../../models/payment/iamport/IMP";
import { ImpCertificationRequestModel, ImpCertificationResponseModel } from "../../../../models/payment/iamport/ImpModels";
import { Redirect } from "react-router-dom";
import { videoPages } from "../../../../constants/PagePaths";
import AlertContext from "../../../../contexts/AlertContext";

enum MemberType {
  Person = "person",
  Company = "company",
}

interface Props {}

interface State {
  isEmailConfirmed: boolean;
  isPhoneConfirmed: boolean;
  isCompanyConfirmed: boolean;
  isPostShow: boolean;
  dateDialogOpen: boolean;
  birth: Date;
  zoneCode: string;
  fullAddress: string;
  isCompletedSignUp: boolean;
  isReturnToIndex: boolean;
  selectedGender: string;
  selectedMemberType: MemberType;
  agreeChecks: boolean[];
  isAllAgreeCheck: boolean;
  isEnableMotivOther: boolean;
  businessNumber: string;
}

export default class VideoSignUp extends React.Component<Props, State> {
  state: State = {
    isEmailConfirmed: false,
    isPostShow: false,
    isPhoneConfirmed: false,
    isCompanyConfirmed: false,
    dateDialogOpen: false,
    birth: new Date("1990-01-01"),
    fullAddress: "",
    zoneCode: "",
    isCompletedSignUp: false,
    selectedGender: "femail",
    agreeChecks: [false, false, false, false],
    isAllAgreeCheck: false,
    isEnableMotivOther: false,
    isReturnToIndex: false,
    selectedMemberType: MemberType.Person,
    businessNumber: "",
  };

  idInput = createRef<HTMLInputElement>();
  pwInput1 = createRef<HTMLInputElement>();
  pwInput2 = createRef<HTMLInputElement>();
  nameInput = createRef<HTMLInputElement>();
  phoneNumberInput = createRef<HTMLInputElement>();
  addressInput = createRef<HTMLInputElement>();
  detailAddressInput = createRef<HTMLInputElement>();
  certificationKey = "";

  onChangeAgree = (index: number, value: boolean) => {
    const checkes = this.state.agreeChecks;
    checkes[index] = value;
    this.setState({
      isAllAgreeCheck: checkes.every((check) => check),
    });
  };

  onChangeAgreeAll = (checked: boolean) => {
    this.setState({
      agreeChecks: [checked, checked, checked, checked],
      isAllAgreeCheck: checked,
    });
  };

  handleGenderChange = (value: string) => {
    this.setState({
      selectedGender: value,
    });
  };

  checkDuplicateAsync = async () => {
    if (!emailRegex.test(this.idInput.current?.value || "")) {
      this.context.showAlert("올바른 이메일 주소를 입력하세요.");
      return;
    }

    const res = await api.checkEmailAsync(this.idInput.current?.value || "");
    if (res) {
      this.context.showAlert("사용 가능한 이메일입니다.");
      this.setState({
        isEmailConfirmed: true,
      });
    } else {
      this.context.showAlert("이미 사용중인 이메일입니다.");
    }
  };

  onClickCertification = () => {
    if (
      !this.state.birth ||
      this.state.birth.getTime() === new Date("1990-01-01").getTime() ||
      !this.nameInput.current?.value ||
      !this.phoneNumberInput.current?.value
    ) {
      this.context.showAlert("이름, 생년월일, 휴대폰 번호를 입력해주세요.");
      return;
    }

    const data: ImpCertificationRequestModel = {
      merchant_uid: `mid_${new Date().getTime()}`,
      company: `https://${process.env.REACT_APP_DOMAIN}.com/`,
      name: this.nameInput.current?.value,
      phone: this.phoneNumberInput.current?.value,
    };
    Imp.certification(data, async (res: ImpCertificationResponseModel) => {
      if (!res.success && res.error_code === "F0000") {
        return;
      }
      try {
        const result = await api.postCertificationIsNotDuplicateAsync({
          imp_uid: res.imp_uid,
          birth: this.state.birth.format("yyyy-MM-dd"),
          name: this.nameInput.current?.value,
          phone: this.phoneNumberInput.current?.value,
        });

        if (result.status === 203) {
          this.context.showAlert(
            "기입된 정보와 본인인증 정보가 일치하지 않습니다."
          );
          return;
        }

        if (!result.data.is_duplicated) {
          this.setState({
            isPhoneConfirmed: true,
          });
          this.certificationKey = result.data.certification_key;
        } else {
          this.context.showAlert("이미 가입된 휴대전화 번호입니다.");
          this.certificationKey = "";
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  handleBusinessNumberChanged = (e: any) => {
    this.setState({
      businessNumber: e.target?.value || "",
      isCompanyConfirmed: false,
    });
  };

  componentDidMount(): void {
    const key = util.getCookie(Cookies.authKey);
    if (key) {
      this.setState({
        isReturnToIndex: true,
      });
    }
  }

  onChangeId = () => {
    this.setState({
      isEmailConfirmed: false,
    });
  };

  handlePhoneNumberChange = () => {
    this.setState({
      isPhoneConfirmed: false,
    });
  };

  handleDateClose = (result: Date) => {
    this.setState({
      birth: result || this.state.birth,
      dateDialogOpen: false,
    });
  };

  handlePrivacyClick = () => {
    this.context.showTextModal(privacyPolicy, () => {
      this.onChangeAgree(0, true);
    });
  };

  handleTermsClick = () => {
    this.context.showTextModal(termsOfService, () => {
      this.onChangeAgree(1, true);
    });
  };

  handleSubmitAsync = async (e: React.FormEvent<HTMLFormElement>) => {
    const values: { [key: string]: any[] } = {};
    try {
      e.preventDefault();

      if (!this.state.isEmailConfirmed) {
        this.context.showAlert("이메일 중복체크가 필요합니다.");
        return;
      }

      if (!this.detailAddressInput || !this.state.fullAddress) {
        this.context.showAlert("주소 입력이 필요합니다.");
        return;
      }

      // 회원유형이 기업이고 isCompanyConfirmed가 false일 경우 alert
      if (
        this.state.selectedMemberType === MemberType.Company &&
        !this.state.isCompanyConfirmed
      ) {
        this.context.showAlert("사업자 등록번호 확인이 필요합니다.");
        return;
      } else if (
        this.state.selectedMemberType === MemberType.Person &&
        !this.state.isPhoneConfirmed
      ) {
        this.context.showAlert("휴대폰 인증이 필요합니다.");
        return;
      }

      const form = e as any;
      for (let index = 0; index < form.target.length; index++) {
        const element = form.target[index];
        if (element.type === "text") {
          values[element.name] = element.value;
        } else if (
          (element.type === "radio" || element.type === "checkbox") &&
          element.name != ""
        ) {
          if (element.checked) {
            if (values[element.name] == undefined) {
              values[element.name] = [];
            }
            values[element.name].push(
              element.parentElement.parentElement.parentElement.innerText
            );
          }
        }
      }

      if (values.motivationOther) {
        if (!values.motivation) {
          values.motivation = [];
        }
        if (values.motiveOther.toString().trim().length < 2) {
          this.context.showAlert("기타 입력칸에 2글자 이상 입력해주세요!");
          return;
        }
        values?.motivation?.push(values.motiveOther);
      }

      if (values.interestOtherCheckbox) {
        if (!values.interest) {
          values.interest = [];
        }
        if (values.interestOtherInput.toString().trim().length < 2) {
          this.context.showAlert("기타 입력칸에 2글자 이상 입력해주세요!");
          return;
        }
        values?.interest?.push(values.interestOtherInput);
      }

      if (
        !values.interestOtherCheckbox &&
        (values.interest == undefined || values.interest?.length < 1)
      ) {
        this.context.showAlert("관심분야를 1개 이상 선택해주세요.");
        return;
      }

      if (
        !values.motivationOther &&
        (values.motivation == undefined || values.motivation?.length < 1)
      ) {
        this.context.showAlert("가입동기를 1개 이상 선택해주세요.");
        return;
      }
    } catch (error) {
      console.error(error);
    }

    try {
      const requestData = {
        username: this.idInput.current?.value,
        password1: this.pwInput1.current?.value,
        password2: this.pwInput2.current?.value,
        phone:
          this.state.selectedMemberType === MemberType.Person
            ? this.phoneNumberInput.current?.value
            : values.companyContactNumber || values.faxNumber,
        last_name:
          this.state.selectedMemberType === MemberType.Person
            ? this.nameInput.current?.value
            : values.companyContactName || values.companyName,
        email: this.idInput.current?.value,
        postcode: this.state.zoneCode,
        addr:
          this.state.fullAddress + " " + this.detailAddressInput.current?.value,
        birth: this.state.birth,
        gender: this.state.selectedGender,
        is_message_agree: this.state.agreeChecks[2],
        is_email_agree: this.state.agreeChecks[3],
        interests: values.interest,
        motivations: values.motivation,
        company_name: values.companyName,
        certification_key: this.certificationKey || values.businessNumber,
        fax_number: values.faxNumber,
      };

      await api.postUserRegistrationAsync(requestData);
      this.context.showAlert(
        `완료되었습니다.\n무료기간 ${FreeUseDay}일이 지급되었습니다.\n많은 이용 바랍니다.`
      );
      this.setState({
        isCompletedSignUp: true,
      });
    } catch (error: any) {
      if (error === 400) {
        this.context.showAlert(error?.message);
      } else {
        this.context.showAlert(
          "회원가입 도중 오류가 발생하였습니다.\n우측 하단 고객센터를 통해 문의 부탁드립니다."
        );
      }
    }
  };

  handlePw2Changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.pwInput1.current?.value === e.target.value) {
      this.pwInput2.current?.setCustomValidity("");
    } else {
      this.pwInput2.current?.setCustomValidity("비밀번호가 일치하지 않습니다.");
    }
  };

  handleClickAddress = (e: any) => {
    e.preventDefault();
    this.setState({
      isPostShow: true,
    });
  };

  handlePostComplete = (zoneCode: string, fullAddress: string) => {
    this.setState({
      zoneCode,
      fullAddress,
      isPostShow: false,
    });
  };

  handleMotivOther = (checked: boolean) => {
    this.setState({
      isEnableMotivOther: checked,
    });
  };

  handleCompanyCheckClick = async () => {
    if (!this.state.businessNumber) {
      this.context.showAlert("사업자번호를 입력해주세요.");
      return;
    }
    const res = await api.getBusinessNumberStatus(
      this.state.businessNumber ?? ""
    );
    const businessModel: BusinessApiResponseModel = res?.data?.data?.[0];
    if (businessModel?.b_stt_cd === "01" && businessModel.end_dt == "") {
      // api를 통해 사업자번호 중복확인 요청
      const isDuplicatedRes = await api.getBusinessNumberDuplicate(
        this.state.businessNumber
      );
      if (!isDuplicatedRes?.data?.is_duplicated) {
        this.context.showAlert("정상적인 사업자번호입니다.");
        this.setState({
          isCompanyConfirmed: true,
        });
      } else {
        this.context.showAlert("이미 가입된 사업자번호입니다.");
      }
    } else {
      this.context.showAlert("올바르지 않은 사업자번호입니다.");
    }
  };

  render() {
    if (this.state.isCompletedSignUp) {
      return <Redirect to={videoPages.login} />;
    } else if (this.state.isReturnToIndex) {
      return <Redirect to={videoPages.index} />;
    }
    return (
      <div style={{ width: "100%" }} className={styles["signup-container"]}>
        <DaumPostModal
          changeIsOpen={() => this.setState({ isPostShow: false })}
          isOpen={this.state.isPostShow}
          onComplete={this.handlePostComplete}
        />
        <div className={styles["signup-title"]}>회원가입</div>
        <form onSubmit={this.handleSubmitAsync} noValidate={false}>
          <div className={styles["user_info_container"]}>
            <div className={styles.form_item}>
              <span className="text-label">
                아이디(이메일)
                <span className={styles["star"]}>*</span>
              </span>
              <input
                ref={this.idInput}
                type="email"
                placeholder="이메일 주소를 입력해주세요."
                className={styles["dk-input"]}
                onChange={this.onChangeId}
                pattern="^(([^<>()[\]\\.,;:\s@\x22]+(\.[^<>()[\]\\.,;:\s@\x22]+)*)|(\x22.+\x22))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$"
                required
              />
              {!this.state.isEmailConfirmed && (
                <button type="button" onClick={this.checkDuplicateAsync}>
                  중복체크
                </button>
              )}
              {this.state.isEmailConfirmed && (
                <span
                  style={{
                    color: "rgb(68, 132, 255)",
                    fontWeight: "bold",
                    fontSize: "28px",
                    paddingTop: "6px",
                    marginLeft: "10px",
                  }}
                >
                  &#10003;
                </span>
              )}
            </div>
            <div className={styles.form_item}>
              <span className="text-label">
                비밀번호
                <span className={styles["star"]}>*</span>
              </span>
              <input
                ref={this.pwInput1}
                type="password"
                placeholder="영문/숫자/특수문자 조합으로 8~15자리"
                className={styles["dk-input"]}
                pattern="^((?=.*\d)(?=.*[A-z])(?=.*[\W]).{8,15})$"
                required
              />
            </div>
            <div className={styles.form_item}>
              <span className="text-label">
                비밀번호 확인
                <span className={styles["star"]}>*</span>
              </span>
              <input
                ref={this.pwInput2}
                type="password"
                placeholder="비밀번호 재입력"
                className={styles["dk-input"]}
                onChange={this.handlePw2Changed}
                pattern="^((?=.*\d)(?=.*[A-z])(?=.*[\W]).{8,15})$"
                required
              />
            </div>
            <div className={styles.form_item}>
              <span className="text-label">
                회원 유형
                <span className={styles["star"]}>*</span>
              </span>
              <label>
                <Radio
                  required
                  checked={this.state.selectedMemberType === MemberType.Person}
                  onChange={(e: any) =>
                    this.setState({ selectedMemberType: e.target.value })
                  }
                  value={MemberType.Person}
                  name="memberType"
                />
                개인
              </label>
              <label>
                <Radio
                  required
                  checked={this.state.selectedMemberType === MemberType.Company}
                  onChange={(e: any) =>
                    this.setState({ selectedMemberType: e.target.value })
                  }
                  value={MemberType.Company}
                  name="memberType"
                />
                기업
              </label>
            </div>
            {this.state.selectedMemberType === MemberType.Company && (
              <div>
                {/* 기업명 */}
                <div className={styles.form_item}>
                  <span className="text-label">
                    기업명
                    <span className={styles["star"]}>*</span>
                  </span>
                  <input
                    type="text"
                    name="companyName"
                    placeholder="기업명을 입력해주세요."
                    className={styles["dk-input"]}
                    required
                  />
                </div>
                {/* 사업자번호 입력 input */}
                <div className={styles.form_item}>
                  <span className="text-label">
                    사업자번호
                    <span className={styles["star"]}>*</span>
                  </span>
                  <input
                    type="text"
                    name="businessNumber"
                    placeholder="사업자번호를 입력해주세요."
                    className={styles["dk-input"]}
                    required
                    onChange={this.handleBusinessNumberChanged}
                  />
                  {!this.state.isCompanyConfirmed && (
                    <button
                      type="button"
                      onClick={this.handleCompanyCheckClick}
                      style={{ marginLeft: "10px" }}
                      className={styles["dk-btn"]}
                    >
                      사업자번호 확인
                    </button>
                  )}
                  {this.state.isCompanyConfirmed && (
                    <span
                      style={{
                        color: "rgb(68, 132, 255)",
                        fontWeight: "bold",
                        fontSize: "28px",
                        paddingTop: "6px",
                        marginLeft: "10px",
                      }}
                    >
                      &#10003;
                    </span>
                  )}
                </div>
                {/* Fax 번호 입력 */}
                <div className={styles.form_item}>
                  <span className="text-label">
                    Fax 번호
                    <span className={styles["star"]}>*</span>
                  </span>
                  <input
                    type="text"
                    name="faxNumber"
                    placeholder="Fax 번호를 입력해주세요."
                    className={styles["dk-input"]}
                    required
                  />
                </div>
                {/* 담당자 정보 입력 */}
                <div className={styles.form_item}>
                  <span className="text-label">담당자 성함</span>
                  <input
                    type="text"
                    name="companyContactName"
                    placeholder="담당자 성함을 입력해주세요."
                    className={styles["dk-input"]}
                  />
                </div>
                <div className={styles.form_item}>
                  <span className="text-label">담당자 연락처</span>
                  <input
                    type="text"
                    name="companyContactNumber"
                    placeholder="담당자 연락처를 입력해주세요."
                    className={styles["dk-input"]}
                  />
                </div>
              </div>
            )}
            {this.state.selectedMemberType === MemberType.Person && (
              <React.Fragment>
                <div className={styles.form_item}>
                  <span className="text-label">
                    이름
                    <span className={styles["star"]}>*</span>
                  </span>
                  <input
                    ref={this.nameInput}
                    placeholder="이름"
                    className={styles["dk-input"]}
                    required
                  />
                </div>
                <div className={styles.form_item}>
                  <span className="text-label">
                    생년월일<span className={styles["star"]}>*</span>
                  </span>
                  <div className={styles["birth-wrapper"]}>
                    <div
                      onClick={() => this.setState({ dateDialogOpen: true })}
                    >
                      {this.state.birth.format("yyyy-MM-dd")}
                    </div>
                  </div>
                </div>
                <div className={styles.form_item}>
                  <span className="text-label">
                    휴대폰 번호<span className={styles["star"]}>*</span>
                  </span>
                  <input
                    ref={this.phoneNumberInput}
                    onChange={this.handlePhoneNumberChange}
                    placeholder="'-'를 제외한 휴대폰 번호"
                    className={styles["dk-input"]}
                    pattern="[0-9]{11}"
                    required
                  />
                  {this.state.isPhoneConfirmed ? (
                    <span
                      style={{
                        color: "rgb(68, 132, 255)",
                        fontWeight: "bold",
                        fontSize: "28px",
                        paddingTop: "6px",
                        marginLeft: "10px",
                      }}
                    >
                      &#10003;
                    </span>
                  ) : (
                    <button type="button" onClick={this.onClickCertification}>
                      인증하기
                    </button>
                  )}
                </div>
              </React.Fragment>
            )}
            <div className={styles.form_item}>
              <span className="text-label">
                주소<span className={styles["star"]}>*</span>
              </span>
              {/** 주소 input을 클릭하더라도 검색창 띄우기 */}
              <input
                ref={this.addressInput}
                readOnly={true}
                className={styles["dk-input"]}
                style={{ width: "205px", marginRight: "10px" }}
                onClick={this.handleClickAddress}
                required
                value={this.state.fullAddress}
              />
              <input
                readOnly={true}
                className={styles["dk-input"]}
                style={{ width: "70px" }}
                required
                onClick={this.handleClickAddress}
                value={this.state.zoneCode}
              />
              <button type="button" onClick={this.handleClickAddress}>
                검색
              </button>
            </div>
            <div className={styles.form_item}>
              <span className="text-label">&nbsp;</span>
              <input
                ref={this.detailAddressInput}
                placeholder="상세주소를 입력하세요."
                className={styles["dk-input"]}
                formNoValidate={false}
                required
              />
            </div>
          </div>
          <div className={styles.user_info_container}>
            {this.state.selectedMemberType === MemberType.Person && (
              <div className={styles.form_item}>
                <span className="text-label">
                  성별<span className={styles["star"]}>*</span>
                </span>
                <label>
                  <Radio
                    required
                    checked={this.state.selectedGender === "femail"}
                    onChange={(e) => this.handleGenderChange(e.target.value)}
                    value="femail"
                    name="gender"
                  />
                  여성
                </label>
                <label>
                  <Radio
                    required
                    checked={this.state.selectedGender === "mail"}
                    onChange={(e) => this.handleGenderChange(e.target.value)}
                    value="mail"
                    name="gender"
                  />
                  남성
                </label>
                <label>
                  <Radio
                    required
                    checked={this.state.selectedGender === "other"}
                    onChange={(e) => this.handleGenderChange(e.target.value)}
                    value="other"
                    name="gender"
                  />
                  기타
                </label>
              </div>
            )}
            <div className={styles.form_item}>
              <span>
                가입동기<span className={styles["star"]}>*</span>
                <br />
                <span
                  style={{
                    fontSize: "small",
                    color: "rgb(158, 158, 158)",
                  }}
                >
                  (1개 이상 선택)
                </span>
              </span>
              <div className={styles.checkbox_container}>
                <div className={styles.multi_select_container}>
                  <Checkbox className={styles.checkbox} name="interest" />
                  <span className={styles.text}>온라인 검색</span>
                </div>
                <div className={styles.multi_select_container}>
                  <Checkbox className={styles.checkbox} name="interest" />
                  <span className={styles.text}>카페, 블로그</span>
                </div>
                <div className={styles.multi_select_container}>
                  <Checkbox className={styles.checkbox} name="interest" />
                  <span className={styles.text}>전단지, 판촉물</span>
                </div>
                <div className={styles.multi_select_container}>
                  <Checkbox className={styles.checkbox} name="interest" />
                  <span className={styles.text}>유튜브</span>
                </div>
                <div className={styles.multi_select_container}>
                  <Checkbox className={styles.checkbox} name="interest" />
                  <span className={styles.text}>지인 추천</span>
                </div>
                <div className={styles.multi_select_container}>
                  <Checkbox className={styles.checkbox} name="interest" />
                  <span className={styles.text}>이전 직장에서 사용</span>
                </div>
                <div className={styles.multi_select_container}>
                  <Checkbox className={styles.checkbox} name="interest" />
                  <span className={styles.text}>
                    SNS(페이스북, 인스타그램 등)
                  </span>
                </div>
                <div className={styles.multi_select_container}>
                  <Checkbox
                    name="interestOtherCheckbox"
                    className={styles.checkbox}
                    onChange={(e) => this.handleMotivOther(e.target.checked)}
                  />
                  <span className={styles.text} style={{ marginRight: "5px" }}>
                    기타
                  </span>
                  <input
                    placeholder="2글자 이상"
                    name="interestOtherInput"
                    className={styles["dk-input"]}
                    style={{ width: "170px" }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.form_item}>
              <span>
                관심분야<span className={styles["star"]}>*</span>
                <br />
                <span
                  style={{
                    fontSize: "small",
                    color: "rgb(158, 158, 158)",
                  }}
                >
                  (1개 이상 선택)
                </span>
              </span>
              <div className={styles.checkbox_container}>
                <div className={styles.multi_select_container}>
                  <Checkbox className={styles.checkbox} name="motivation" />
                  <span className={styles.text}>시공 부문</span>
                </div>
                <div className={styles.multi_select_container}>
                  <Checkbox className={styles.checkbox} name="motivation" />
                  <span className={styles.text}>설계 부문</span>
                </div>
                <div className={styles.multi_select_container}>
                  <Checkbox className={styles.checkbox} name="motivation" />
                  <span className={styles.text}>공무 부문</span>
                </div>
                <div className={styles.multi_select_container}>
                  <Checkbox className={styles.checkbox} name="motivation" />
                  <span className={styles.text}>건설회계 부문</span>
                </div>
                <div className={styles.multi_select_container}>
                  <Checkbox
                    name="motivationOther"
                    className={styles.checkbox}
                    onChange={(e) => this.handleMotivOther(e.target.checked)}
                  />
                  <span className={styles.text} style={{ marginRight: "5px" }}>
                    기타
                  </span>
                  <input
                    placeholder="2글자 이상"
                    name="motiveOther"
                    className={styles["dk-input"]}
                    style={{ width: "170px" }}
                  />
                </div>
              </div>
            </div>
            {this.state.selectedMemberType === MemberType.Person && (
              <div className={styles.form_item}>
                <span>회사명</span>
                <div className={styles.checkbox_container}>
                  <div className={styles.multi_select_container}>
                    <input
                      name="companyName"
                      className={styles["dk-input"]}
                      style={{ width: "170px" }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.user_info_container}>
            <div
              className={styles["terms_item"]}
              style={{ marginBottom: "15px" }}
            >
              <Checkbox
                checked={this.state.isAllAgreeCheck}
                onChange={(e) => this.onChangeAgreeAll(e.target.checked)}
              />
              <span className={styles.entire_agree}>전체 동의</span>
            </div>
            <div className={styles["terms_item"]}>
              <Checkbox
                required
                checked={this.state.agreeChecks[0]}
                onChange={(e) => this.onChangeAgree(0, e.target.checked)}
              />
              <span className={styles.necessary}>(필수)&nbsp;</span>
              <span
                className={styles.under_line}
                onClick={this.handlePrivacyClick}
              >
                개인정보 처리방침
              </span>
              <span>에 동의</span>
            </div>
            <div className={styles["terms_item"]}>
              <Checkbox
                required
                checked={this.state.agreeChecks[1]}
                onChange={(e) => this.onChangeAgree(1, e.target.checked)}
              />
              <span className={styles.necessary}>(필수)&nbsp;</span>
              <span
                className={styles.under_line}
                onClick={this.handleTermsClick}
              >
                이용약관
              </span>
              <span>에 동의</span>
            </div>
            <div className={styles["terms_item"]}>
              <Checkbox
                name="messageReceiveAgree"
                checked={this.state.agreeChecks[2]}
                onChange={(e) => this.onChangeAgree(2, e.target.checked)}
              />
              <span className={styles.necessary}>(선택)&nbsp;</span>
              <span>문자 수신 동의(마케팅)</span>
            </div>
            <div className={styles["terms_item"]}>
              <Checkbox
                name="emailReceiveAgree"
                checked={this.state.agreeChecks[3]}
                onChange={(e) => this.onChangeAgree(3, e.target.checked)}
              />
              <span className={styles.necessary}>(선택)&nbsp;</span>
              <span>이메일 수신 동의(마케팅)</span>
            </div>
            <button type="submit" className={styles["submit-button"]}>
              회원가입
            </button>
          </div>
        </form>
        {this.state.dateDialogOpen ? (
          <SimpleDatePicker
            onClose={this.handleDateClose}
            open={this.state.dateDialogOpen}
            selectedDate={this.state.birth}
          ></SimpleDatePicker>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

VideoSignUp.contextType = AlertContext;
