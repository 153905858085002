import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { reject } from "lodash";
import { Cookies } from "../constants/Cookies";
import { businessApiKey } from "../models/apiKey";
import { BusinessApiResponseModel } from "../models/apiModel/apiResponseModel";
import { ImpCallbackResponseModel } from "../models/payment/iamport/ImpModels";
import { UserInfoModel } from "../models/user/UserInfo";
import GlobalEnvironments from "../modules/globalEnvironment/GlobalEnvironment";
import { Project } from "./models";
import { util } from "./util";

export const api = {
  makeQueryString: (args: any) => {
    if (!args) {
      return "";
    } else {
      let queryString = [];
      for (const key of Object.keys(args)) {
        queryString.push(`${key}=${args[key]}`);
      }
      return `?${queryString.join("&")}`;
    }
  },
  uploadProject: (args: {
    copy: any;
    normal: any;
    contract: any;
    change: any;
    ready: any;
    complete: any;
    estimate: any;
  }) => {
    const { copy, normal, contract, change, ready, complete, estimate } = args;
    const key = util.getCookie(Cookies.authKey);
    var config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return new Promise<any>(async (resolve, reject) => {
      axios
        .post(
          `${GlobalEnvironments.getApiUrl()}project/download`,
          {
            copy,
            normal,
            contract,
            change,
            ready,
            complete,
            estimate,
          },
          config
        )
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createProject: (
    title: string,
    tempRow: boolean,
    estimate?: boolean,
    copy?: boolean,
    corporationType?: string,
    memo?: string,
  ) => {
    const key = util.getCookie(Cookies.authKey);
    var config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return new Promise<any>(async (resolve, reject) => {
      axios
        .post(
          `${GlobalEnvironments.getApiUrl()}project/`,
          {
            projectName: title,
            type: estimate ? "estimate" : copy ? "copy" : "normal",
            tempRow: tempRow,
            corporationType,
            memo,
          },
          config
        )
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMyInfo: (): Promise<AxiosResponse<UserInfoModel>> => {
    const key = util.getCookie(Cookies.authKey);
    var config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return axios.get(`${GlobalEnvironments.getApiUrl()}user/info`, config);
  },
  loginWithOption: (
    id: string,
    pw: string,
    autoLogin: boolean,
    saveId: boolean
  ) => {
    const now = new Date();
    saveId
      ? util.setCookie({
          cookieName: Cookies.loginId,
          cookieValue: id,
          cookieExpire: new Date(now.setFullYear(now.getFullYear() + 1)),
        })
      : util.deleteCookie(Cookies.loginId);

    return new Promise<any>(async (resolve, reject) => {
      try {
        const res = await api.loginAsync(id, pw);
        const key = res.data.key;
        if (autoLogin)
          util.setCookie({
            cookieName: Cookies.authKey,
            cookieValue: key,
            cookieExpire: new Date(now.setFullYear(now.getFullYear() + 1)),
            cookieDomain: GlobalEnvironments.getDomainUrl(),
            cookiePath: "/",
          });
        else
          util.setCookie({
            cookieName: Cookies.authKey,
            cookieValue: key,
            cookieDomain: GlobalEnvironments.getDomainUrl(),
            cookiePath: "/",
          });
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },
  loginAsync: async (id: string, pw: string) => {
    return await axios.post(`${GlobalEnvironments.getApiUrl()}login/`, {
      username: id,
      password: pw,
    });
  },
  socialLogin: async (provider: string, code: string, redirectUrl: string) => {
    //
    const res = await axios.post(
      `${GlobalEnvironments.getApiUrl()}login/social/${provider}`,
      {
        code,
        redirectUrl,
      }
    );

    return res;
  },
  socialSignUp: async (provider: string, providerId: string, impId: string) => {
    //
    const res = await axios.post(
      `${GlobalEnvironments.getApiUrl()}user/signup/${provider}`,
      {
        provider,
        providerId,
        impId,
      }
    );

    return res;
  },
  getProjects: (projectType: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return new Promise<any>(async (resolve, reject) => {
      axios
        .get(
          `${GlobalEnvironments.getApiUrl()}project/?project_type=${projectType}`,
          config
        )
        .then((result) => {
          const projects = [];
          for (const p of result.data.projects) {
            projects.push(new Project(p));
          }
          resolve(projects);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createEtcProject: (args: {
    id: number;
    type: string;
    originId: number;
  }): Promise<any> => {
    const { id, type, originId } = args;
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return new Promise(async (resolve, reject) => {
      axios
        .post(
          `${GlobalEnvironments.getApiUrl()}project/etc`,
          { id, type, originId },
          config
        )
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getProjectInfo: (args: any): Promise<any> => {
    const { projectId } = args;
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return new Promise(async (resolve, reject) => {
      axios
        .get(
          `${GlobalEnvironments.getApiUrl()}project/statement?projectId=${projectId}`,
          config
        )
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveProject: (args: any) => {
    const {
      projectId,
      projectName,
      statements,
      priceCalculations,
      laborPrices,
      itemizeCosts,
      machinePrices,
      materialPrices,
      invoiceRate,
      projectInfo,
      permissions,
      exchangeRate,
      wageFactor,
      decimalPoint,
      etc,
      readyLength,
      projectStartDate,
      projectEndDate,
    } = args;
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return new Promise(async (resolve, reject) => {
      axios
        .put(
          `${GlobalEnvironments.getApiUrl()}project/save`,
          {
            projectName,
            projectId,
            statements,
            priceCalculations,
            laborPrices,
            itemizeCosts,
            machinePrices,
            materialPrices,
            invoiceRate,
            projectInfo,
            permissions,
            exchangeRate,
            wageFactor,
            decimalPoint,
            etc,
            readyLength,
            projectStartDate,
            projectEndDate,
          },
          config
        )
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteProject: (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return new Promise<any>(async (resolve, reject) => {
      axios
        .delete(
          `${GlobalEnvironments.getApiUrl()}project/?project_id=${id}`,
          config
        )
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getXmlProject: (id: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        id,
      },
    };
    return new Promise<any>(async (resolve, reject) => {
      axios
        .get(`${GlobalEnvironments.getApiUrl()}export_xml`, config)
        .then((result) => {
          resolve(result?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRelativeProjects: (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const args = api.makeQueryString({ project_id: id });
    return new Promise<any>(async (resolve, reject) => {
      axios
        .get(`${GlobalEnvironments.getApiUrl()}project/${args}`, config)
        .then((result) => {
          const projects = [];
          for (const p of result.data.projects) {
            projects.push(new Project(p));
          }
          resolve(projects);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  checkEmailAsync: async (email: string) => {
    const config: AxiosRequestConfig = {
      params: {
        email,
      },
    };
    return await new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(
          `${GlobalEnvironments.getApiUrl()}user/check`,
          config
        );
        resolve(res.data.result);
      } catch (error) {
        reject(error);
      }
    });
  },
  checkPasswordAsync: async (password: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await await axios.post(
      `${GlobalEnvironments.getApiUrl()}user/checkPw/`,
      {
        password,
      },
      config
    );
  },
  postWorkCompany: (values: any) => {
    //업체정보 등록
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return axios.post(
      `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_company`,
      {
        companyName: values.companyName,
        representativeName: values.representativeName,
        corporateRegistNum: values.corporateRegistNum,
        corporateNum: values.corporateNum,
        businessAddress: values.businessAddress,
        typeOfBusiness: values.typeOfBusiness,
        phoneNum: values.phoneNum,
        faxNum: values.faxNum,
        writer: values.writer,
        bankName: values.bankName,
        accountNum: values.accountNum,
        accountHolder: values.accountHolder,
        typeOfDeposit: values.typeOfDeposit,
        storeName: values.storeName,
      },
      config
    );
  },

  getWorkCompanyList: (args: any) => {
    //업체 리스트 정보 불러오기 (여러개)
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const offset = args.offset || 0;
    const limit = args.limit || 40;
    const queryString = api.makeQueryString({ offset, limit });

    return axios.get(
      `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_company${queryString}`,
      config
    );
  },

  editProjectInfo: (project: Project) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return new Promise<any>(async (resolve, reject) => {
      axios
        .put(
          `${GlobalEnvironments.getApiUrl()}project/`,
          {
            project: {
              id: project.id,
              name: project.name?.trim(),
              companyName: project.companyName,
              clientName: project.clientName,
              corporationType: project.corporationType,
              memo: project.memo,
              startDate: project.startDate
                ? project.startDate.format("yyyy-MM-dd HH:mm:ss")
                : null,
              endDate: project.endDate
                ? project.endDate.format("yyyy-MM-dd HH:mm:ss")
                : null,
            },
          },
          config
        )
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  putWorkCompany: (object: any, idState: any) => {
    //업체 정보 수정
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };

    return axios.put(
      `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_company`,
      {
        companyName: object.companyName,
        representativeName: object.representativeName,
        corporateRegistNum: object.corporateRegistNum,
        corporateNum: object.corporateNum,
        businessAddress: object.businessAddress,
        typeOfBusiness: object.typeOfBusiness,
        phoneNum: object.phoneNum,
        faxNum: object.faxNum,
        writer: object.writer,
        bankName: object.bankName,
        accountNum: object.accountNum,
        accountHolder: object.accountHolder,

        typeOfDeposit: object.typeOfDeposit,
        storeName: object.storeName,

        company_id: idState,
      },
      config
    );
  },

  deleteWorkCompany: (idState: any) => {
    //업체 정보 삭제
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const company_id = idState;
    const queryString = api.makeQueryString({ company_id });

    return axios.delete(
      `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_company${queryString}`,
      config
    );
  },

  postDeputy: (values: any) => {
    //현장대리인 등록
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };

    return axios.post(
      `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_deputy`,
      {
        deputyName: values.deputyName,
        deputyResidentNum: values.deputyResidentNum,
        deputyAddress: values.deputyAddress,
        deputyPosition: values.deputyPosition,
        deputyEvent: values.deputyEvent,
        deputyGrade: values.deputyGrade,
        deputyRegistNum: values.deputyRegistNum,
        deputyRegistDate: values.deputyRegistDate,

        deputyGetDate: values.deputyGetDate,
        deputyRenewDate: values.deputyRenewDate,
        deputyJoinDate: values.deputyJoinDate,
        deputyWorkPlace: values.deputyWorkPlace,
      },
      config
    );
  },

  getDeputyList: (args: any) => {
    //현장대리인 리스트 정보 불러오기 (여러개)
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const offset = args.offset || 0;
    const limit = args.limit || 40;
    const queryString = api.makeQueryString({ offset, limit });

    return axios.get(
      `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_deputy${queryString}`,
      config
    );
  },

  putDeputy: (object: any, idState: any) => {
    //현장대리인 수정
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return axios.put(
      `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_deputy`,
      {
        deputyName: object.deputyName,
        deputyResidentNum: object.deputyResidentNum,
        deputyAddress: object.deputyAddress,
        deputyPosition: object.deputyPosition,
        deputyEvent: object.deputyEvent,
        deputyGrade: object.deputyGrade,
        deputyRegistNum: object.deputyRegistNum,
        deputyRegistDate: object.deputyRegistDate,
        deputy_id: idState,

        deputyGetDate: object.deputyGetDate,
        deputyRenewDate: object.deputyRenewDate,
        deputyJoinDate: object.deputyJoinDate,
        deputyWorkPlace: object.deputyWorkPlace,
      },
      config
    );
  },

  deleteDeputy: (idState: any) => {
    //현장대리인 삭제
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const deputy_id = idState;
    const queryString = api.makeQueryString({ deputy_id });

    return axios.delete(
      `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_deputy${queryString}`,
      config
    );
  },

  postWorklist: (object: any) => {
    //나의 업무목록 등록
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return axios.post(
      `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_list`,
      {
        contractKind: object.contractKind,
        constructionName: object.constructionName,
        typeOfWork: object.typeOfWork,

        companyName: object.companyName,
        representativeName: object.representativeName,
        corporateRegistNum: object.corporateRegistNum,
        corporateNum: object.corporateNum,
        businessAddress: object.businessAddress,
        typeOfBusiness: object.typeOfBusiness,
        phoneNum: object.phoneNum,
        faxNum: object.faxNum,
        writer: object.writer,
        bankName: object.bankName,
        accountNum: object.accountNum,
        accountHolder: object.accountHolder,

        deputyName: object.deputyName,
        deputyResidentNum: object.deputyResidentNum,
        deputyAddress: object.deputyAddress,
        deputyPosition: object.deputyPosition,
        deputyEvent: object.deputyEvent,
        deputyGrade: object.deputyGrade,
        deputyRegistNum: object.deputyRegistNum,
        deputyRegistDate: object.deputyRegistDate,

        contractDate: object.contractDate,
        GuaranteeDate: object.GuaranteeDate,
        claimDate: object.claimDate,
        beginDate: object.beginDate,
        completionDate: object.completionDate,
        realCompletionDate: object.realCompletionDate,
        completionExpectDate: object.completionExpectDate,

        deputyGetDate: object.deputyGetDate,
        deputyRenewDate: object.deputyRenewDate,
        deputyJoinDate: object.deputyJoinDate,
        deputyWorkPlace: object.deputyWorkPlace,
        contractRegion: object.contractRegion,
        contractRepairRate: object.contractRepairRate,
        compensationOfDeferment: object.compensationOfDeferment,
        contractRepairTerm: object.contractRepairTerm,
        storeName: object.storeName,
        typeOfDeposit: object.typeOfDeposit,

        safetyManagePlan: object.safetyManagePlan,
        safetyManageState: object.safetyManageState,
        enviManagePlan: object.enviManagePlan,
        enviManageState: object.enviManageState,
        photoListContent: object.photoListContent,
      },
      config
    );
  },

  getWorklists: async (args: any) => {
    //나의 업무목록 리스트 정보 불러오기 (여러개)
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const offset = args.offset || 0;
    const limit = args.limit || 40;
    const queryString = api.makeQueryString({ offset, limit });
    return await new Promise(async (resolve, reject) => {
      try {
        const res = axios.get(
          `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_list${queryString}`,
          config
        );
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },

  getWorklistImages: async (args: { worklist_id: number }) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const { worklist_id } = args;
    const queryString = api.makeQueryString({ worklist_id });

    //axios.get(
    return await new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(
          `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_image${queryString}`,
          config
        );
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },

  uploadWorklistImage: async (args: {
    imageFile: any;
    worklist_id: number;
    image_number: number;
    image_sub_number: number;
  }) => {
    const { imageFile, worklist_id, image_number, image_sub_number } = args;
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };

    const form = new FormData();
    form.append("imageFile", imageFile);
    form.append("worklist_id", worklist_id.toString());
    form.append("image_number", image_number.toString());
    form.append("image_sub_number", image_sub_number.toString());
    return await new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(
          `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_image`,
          form,
          config
        );
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },

  deleteWorklistImage: (boardImageId: number) => {
    //이미지 삭제
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const board_image_id = boardImageId;
    const queryString = api.makeQueryString({ board_image_id });
    return axios.delete(
      `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_image${queryString}`,
      config
    );
  },

  getWorklist: (args: { id: number }) => {
    //나의 업무목록 리스트 정보 불러오기 (하나)
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const worklist_id = args.id;
    const queryString = api.makeQueryString({ worklist_id });
    return axios.get(
      `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_list${queryString}`,
      config
    );
  },

  putWorklist: (object: any, idState: any) => {
    //나의 업무목록 수정
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };

    return axios.put(
      `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_list`,
      {
        constructionName: object.constructionName,
        organName: object.organName,
        orderOrganization: object.orderOrganization,
        constructionAddress: object.constructionAddress,
        contractDate: object.contractDate,
        contractPrice: object.contractPrice,
        contractGuaranteePrice: object.contractGuaranteePrice,
        GuaranteeDate: object.GuaranteeDate,
        claimPrice: object.claimPrice,
        claimDate: object.claimDate,
        beginDate: object.beginDate,
        completionDate: object.completionDate,
        completionCalPrice: object.completionCalPrice,
        realCompletionDate: object.realCompletionDate,
        dear: object.dear,
        companyName: object.companyName,
        representativeName: object.representativeName,
        corporateRegistNum: object.corporateRegistNum,
        corporateNum: object.corporateNum,
        businessAddress: object.businessAddress,
        typeOfWork: object.typeOfWork,
        typeOfBusiness: object.typeOfBusiness,
        phoneNum: object.phoneNum,
        faxNum: object.faxNum,
        writer: object.writer,
        bankName: object.bankName,
        accountNum: object.accountNum,
        accountHolder: object.accountHolder,
        depositType: object.depositType,
        deputyName: object.deputyName,
        deputyResidentNum: object.deputyResidentNum,
        deputyAddress: object.deputyAddress,
        deputyPosition: object.deputyPosition,
        deputyEvent: object.deputyEvent,
        deputyGrade: object.deputyGrade,
        deputyRegistNum: object.deputyRegistNum,
        deputyRegistDate: object.deputyRegistDate,
        completionExpectDate: object.completionExpectDate,
        contractKind: object.contractKind,

        image: object.image,

        deputyGetDate: object.deputyGetDate,
        deputyRenewDate: object.deputyRenewDate,
        deputyJoinDate: object.deputyJoinDate,
        deputyWorkPlace: object.deputyWorkPlace,
        contractRegion: object.contractRegion,
        contractRepairRate: object.contractRepairRate,
        compensationOfDeferment: object.compensationOfDeferment,
        contractRepairTerm: object.contractRepairTerm,
        storeName: object.storeName,
        typeOfDeposit: object.typeOfDeposit,

        safetyManagePlan: object.safetyManagePlan,
        safetyManageState: object.safetyManageState,
        enviManagePlan: object.enviManagePlan,
        enviManageState: object.enviManageState,
        photoListContent: object.photoListContent,

        expensedSafetyPlanCost: object.expensedSafetyPlanCost,
        expensedSafetyStateCost: object.expensedSafetyStateCost,
        expensedEnviPlanCost: object.expensedEnviPlanCost,
        expensedEnviStateCost: object.expensedEnviStateCost,

        worklist_id: idState,
      },
      config
    );
  },

  deleteWorklist: (idState: any) => {
    //나의 업무목록 삭제
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const worklist_id = idState;
    const queryString = api.makeQueryString({ worklist_id });

    return axios.delete(
      `${GlobalEnvironments.getApiUrl()}auto_work/work_project/work_list${queryString}`,
      config
    );
  },

  logout: () => {
    //로그아웃
    const key = util.getCookie(Cookies.authKey);
    var config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    util.deleteCookie(Cookies.authKey);
    return axios.post(
      `${GlobalEnvironments.getApiUrl()}rest-auth/logout/`,
      {},
      config
    );
  },
  viewAllAlarms: (): Promise<any> => {
    const key = util.getCookie(Cookies.authKey);
    var config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return new Promise(async (resolve, reject) => {
      axios
        .put(`${GlobalEnvironments.getApiUrl()}alarm/`, {}, config)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  viewAlarm: (args: { id: number }): Promise<any> => {
    const key = util.getCookie(Cookies.authKey);
    var config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const { id } = args;
    return new Promise(async (resolve, reject) => {
      axios
        .put(`${GlobalEnvironments.getApiUrl()}alarm/`, { id }, config)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getNoticesAsync: async (offset: number, limit: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config: AxiosRequestConfig = {
      params: {
        offset,
        limit,
      },
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(
          `${GlobalEnvironments.getApiUrl()}notice/`,
          config
        );
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAlarmsAsync: async (offset: number, limit: number, view?: boolean) => {
    const key = util.getCookie(Cookies.authKey);
    const config: AxiosRequestConfig = {
      params: {
        offset,
        limit,
        view,
      },
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(
          `${GlobalEnvironments.getApiUrl()}alarm/`,
          config
        );
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },

  getNotice: (args: { id: number }) => {
    //공지사항 목록 불러오기 (하나)
    const id = args.id;
    const queryString = api.makeQueryString({ id });
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };

    return axios.get(
      `${GlobalEnvironments.getApiUrl()}notice${queryString}`,
      config
    );
  },

  getNotices: (args: any) => {
    //공지사항 목록 불러오기 (여러개)
    const offset = args.offset || 0;
    const limit = args.limit || 10;
    const queryString = api.makeQueryString({ offset, limit });

    return axios.get(`${GlobalEnvironments.getApiUrl()}notice${queryString}`);
  },

  getEstimate: () => {
    //용역의뢰 페이지 홍보도표 목록 불러오기
    return axios.get(`${GlobalEnvironments.getApiUrl()}estimate_service`);
  },

  postConstructionImg: (values: any) => {
    //사진대지 등록
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return axios.post(
      `${GlobalEnvironments.getApiUrl()}auto_work/work_project/image_upload`,
      {
        imageFile: values.photo11,
      },
      config
    );
  },
  postInquiry: (values: any) => {
    const key = util.getCookie(Cookies.authKey);
    let config;
    if (key) {
      config = {
        headers: {
          Authorization: `Token ${key}`,
          "Content-Type": `mulripart/form-data`,
        },
      };
    } else {
      config = {
        headers: {
          "Content-Type": `mulripart/form-data`,
        },
      };
    }

    return axios.post(
      `${GlobalEnvironments.getApiUrl()}inquiry/`,
      values,
      config
    );
  },
  getMyInquiry: (limit: number, offset: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };

    return axios.get(
      `${GlobalEnvironments.getApiUrl()}inquiry/my?limit=${limit}&offset=${offset}`,
      config
    );
  },
  getMyInquiryDetail: (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };

    return axios.get(
      `${GlobalEnvironments.getApiUrl()}inquiry/my?id=${id}`,
      config
    );
  },
  getMyInquiryReplyDetail: (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };

    return axios.get(
      `${GlobalEnvironments.getApiUrl()}inquiry/my_reply?id=${id}`,
      config
    );
  },
  postRequestApplication: (values: any) => {
    const config = {
      headers: {
        "Content-Type": `mulripart/form-data`,
      },
    };

    return axios.post(
      `${GlobalEnvironments.getApiUrl()}request_application/`,
      values,
      config
    );
  },
  getAuthUserInfoAsync: async (email: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      params: {
        username: email,
      },
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}rest-auth/user`,
      config
    );
  },
  // bug: 현재는 password_reset_confirm 오류때문에 정상동작 불가.
  resetPasswordAsync: async (email: string) => {
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}rest-auth/password/reset/`,
      {
        email,
      }
    );
  },
  changePasswordAsync: async (new_password: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}rest-auth/password/change/`,
      {
        new_password1: new_password,
        new_password2: new_password,
      },
      config
    );
  },
  // bug: 현재는 password_reset_confirm 오류때문에 정상동작 불가.
  resetConfirmPasswordAsync: async (uid: string, new_password: string) => {
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}rest-auth/password/reset/confirm/`,
      {
        uid,
        token: "sad",
        new_password1: new_password,
        new_password2: new_password,
      }
    );
  },
  postPaymentCompleteAsync: async (res: ImpCallbackResponseModel) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}payment/complete/`,
      res,
      config
    );
  },
  getPaymentProductsAsync: async () => {
    const config = {
      params: {
        is_test: GlobalEnvironments.getIsGetTestProduct(),
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}payment/product`,
      config
    );
  },
  getPaymentHistoryAsync: async (offset?: number, limit?: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        offset,
        limit,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}payment/history/`,
      config
    );
  },
  postWithdrawalAsync: async (reason?: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}user/withdrawal/`,
      {
        reason: reason,
      },
      config
    );
  },
  postCertificationIsNotDuplicateAsync: async (model: any) => {
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}user/certification/`,
      model
    );
  },
  postFindIdAsync: async (data: any) => {
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}user/findId/`,
      data
    );
  },
  postFindPwAsync: async (data: any) => {
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}user/findPw/`,
      data
    );
  },
  postChangePwInFindAccountAsync: async (password: string, token: string) => {
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}rest-auth/password/change/`,
      {
        new_password1: password,
        new_password2: password,
      },
      config
    );
  },
  postUserRegistrationAsync: async (data: any) => {
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}rest-auth/registration/`,
      data
    );
  },
  getCouponHistoryAsync: async (offset?: number, limit?: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        offset,
        limit,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}coupon/history`,
      config
    );
  },
  postRegistCouponAsync: async (couponKey: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}coupon/history`,
      {
        coupon_key: couponKey,
      },
      config
    );
  },
  putCouponUseAsync: async (couponKey: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.put(
      `${GlobalEnvironments.getApiUrl()}coupon/history/`,
      {
        coupon_key: couponKey,
      },
      config
    );
  },
  getCouponUsableAsync: async (key: string) => {
    const config = {
      params: {
        coupon_key: key,
      },
    };
    return await axios.get(`${GlobalEnvironments.getApiUrl()}coupon`, config);
  },
  getCardNewsAsync: async (offset: number, limit: number) => {
    const config = {
      params: {
        offset,
        limit,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}notice/card_news`,
      config
    );
  },
  getLectureAsync: async () => {
    return await axios.get(`${GlobalEnvironments.getApiUrl()}lecture`);
  },
  getLectureCategoryAsync: async () => {
    return await axios.get(`${GlobalEnvironments.getApiUrl()}lecture/category`);
  },
  getSampleStatement: async () => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}sample_statement`,
      config
    );
  },
  getSampleStatementDetail: async (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        id: id,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}sample_statement/detail`,
      config
    );
  },
  postSampleStatemnetRecommend: async (arg: any) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}sample_statement/recommend/`,
      arg,
      config
    );
  },
  getSampleStatementIsRecommend: async (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        id: id,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}sample_statement/recommend`,
      config
    );
  },
  deleteSampleStatementRecommend: async (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        id: id,
      },
    };
    return await axios.delete(
      `${GlobalEnvironments.getApiUrl()}sample_statement/recommend`,
      config
    );
  },
  getFilesFromUrl: async (url: string) => {
    return await axios.get(url);
  },
  deleteSampleStatement: async (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        id: id,
      },
    };
    return await axios.delete(
      `${GlobalEnvironments.getApiUrl()}sample_statement/detail`,
      config
    );
  },
  getUserBoardList: async () => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}user_board`,
      config
    );
  },
  getUserBoardDetail: async (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        id: id,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}user_board/detail`,
      config
    );
  },
  // postUserBoardRecommend: async (arg: any) => {
  //   const key = util.getCookie(Cookies.authKey);
  //   const config = {
  //     headers: {
  //       Authorization: `Token ${key}`,
  //     },
  //   };
  //   return await axios.post(
  //     `${GlobalEnvironments.getApiUrl()}user_board/recommend/`,
  //     arg,
  //     config
  //   );
  // },
  // getUserBoardIsRecommend: async (id: number) => {
  //   const key = util.getCookie(Cookies.authKey);
  //   const config = {
  //     headers: {
  //       Authorization: `Token ${key}`,
  //     },
  //     params: {
  //       id: id,
  //     },
  //   };
  //   return await axios.get(
  //     `${GlobalEnvironments.getApiUrl()}user_board/recommend`,
  //     config
  //   );
  // },
  // deleteUserBoardRecommend: async (id: number) => {
  //   const key = util.getCookie(Cookies.authKey);
  //   const config = {
  //     headers: {
  //       Authorization: `Token ${key}`,
  //     },
  //     params: {
  //       id: id,
  //     },
  //   };
  //   return await axios.delete(
  //     `${GlobalEnvironments.getApiUrl()}user_board/recommend`,
  //     config
  //   );
  // },
  deleteUserBoard: async (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        id: id,
      },
    };
    return await axios.delete(
      `${GlobalEnvironments.getApiUrl()}user_board/detail`,
      config
    );
  },
  uploadFileAsync: async (file: File, path: string = "ddusul") => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const form = new FormData();
    form.append("file", file);
    path && form.append("path", path);
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}upload/`,
      form,
      config
    );
  },
  getBusinessNumberStatus: async (businessNumber: string) => {
    const config = {
      headers: {
        Authorization: businessApiKey,
      },
    };
    return await axios.post(
      `https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=${businessApiKey}`,
      {
        b_no: [businessNumber],
      },
      config
    );
  },
  getBusinessNumberDuplicate: async (businessNumber: string) => {
    const config = {
      params: {
        businessNumber: businessNumber,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}user/business`,
      config
    );
  },
  getMarketingInfo: async () => {
    return await axios.get(`${GlobalEnvironments.getApiUrl()}stats`);
  },
  postServiceRequestDoc: async (data: any) => {
    const key = util.getCookie(Cookies.authKey);
    let config;
    if (key) {
      config = {
        headers: {
          Authorization: `Token ${key}`,
        },
      };
    } else {
      config = {
        headers: {},
      };
    }
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}service_request_doc/`,
      data,
      config
    );
  },
  getMyServiceRequestDoc: async (limit: number, offset: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };

    return axios.get(
      `${GlobalEnvironments.getApiUrl()}service_request_doc/my?limit=${limit}&offset=${offset}`,
      config
    );
  },
  getMyServiceRequestDocDetail: async (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };

    return axios.get(
      `${GlobalEnvironments.getApiUrl()}service_request_doc/my?id=${id}`,
      config
    );
  },
  getDownloadDataSearch: async (
    filename: string,
    offset?: number,
    limit?: number
  ) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        filename,
        offset,
        limit,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}download/search`,
      config
    );
  },
  getDownloadDataLink: async (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        file_id: id,
      },
    };
    return await axios.get(`${GlobalEnvironments.getApiUrl()}download`, config);
  },
  getDownloadDataDetail: async (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}download?id=${id}`,
      config
    );
  },
  likeDownloadData: async (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}download/like`,
      { post_id: id },
      config
    );
  },
  unLikeDownloadData: async (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.delete(
      `${GlobalEnvironments.getApiUrl()}download/like?post_id=${id}`,
      config
    );
  },
  postDownloadDataComment: async (id: number, comment: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}download/comment`,
      { post_id: id, comment: comment },
      config
    );
  },
  deleteDownloadDataComment: async (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.delete(
      `${GlobalEnvironments.getApiUrl()}download/comment?comment_id=${id}`,
      config
    );
  },
  postNoticeComment: async (id: number, text: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}notice/comment`,
      { notice_id: id, text: text },
      config
    );
  },
  deleteNoticeComment: async (id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.delete(
      `${GlobalEnvironments.getApiUrl()}notice/comment?comment_id=${id}`,
      config
    );
  },
  getSheetNamesAsync: async (files: string[]) => {
    const key = util.getCookie(Cookies.authKey);
    const params = new URLSearchParams();
    files.forEach((file) => {
      params.append("files", file);
    });
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params,
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}excel/X2/sheets`,
      config
    );
  },
  postExcelX2Execute: async (data: any) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}excel/X2/execute`,
      data,
      config
    );
  },
  postExcelContractExecute: async (data: any) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}excel/contract/execute`,
      data,
      config
    );
  },
  postExcelReadyExecute: async (data: any) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}excel/ready/execute`,
      data,
      config
    );
  },
  postExcelDeleteExecute: async (data: any) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}excel/delete/execute`,
      data,
      config
    );
  },
  postExcelOverrideExecute: async (data: any) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}excel/override/execute`,
      data,
      config
    );
  },
  postExcelColorExecute: async (data: any) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}excel/color/execute`,
      data,
      config
    );
  },
  postExcelFluctuationExecute: async (data: any) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}excel/fluctuation/execute`,
      data,
      config
    );
  },
  postExcelChangeReportExecute: async (data: any) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}excel/report/execute`,
      data,
      config
    );
  },
  toggleFavoriteProject: async (id: number, isFavorite: boolean) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}project/favorite`,
      { id: id, is_favorite: isFavorite },
      config
    );
  }
};
