/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { api } from "../../config/api";
import "./myPage.scss";
import AlertContext from "../../contexts/AlertContext";
import { pages } from "../../constants/PagePaths";

interface Props {}

interface BoardModel {
  created_at: string;
  id: number;
  is_admin_visible: boolean;
  request_data: any;
  user_id: number;
}

export default () => {
  const alert = useContext(AlertContext);
  const history = useHistory();
  const windowLocation = window.location.href;
  const paths = windowLocation.split("/");
  let lastpath = paths[paths.length - 1];
  const id = lastpath.split("?")[0];
  const [contact, setContact] = useState<BoardModel>();

  useEffect(() => {
    new Promise(async () => {
      const res = await api.getMyServiceRequestDocDetail(Number(id));
      setContact(res.data.data);
    });
  }, []);

  function getSecondKeyValue(data: any) {
    if (!data) {
      return { key: "" };
    }
    const keys = Object.keys(data.request_data);
    const secondKey = keys[0];
    const secondValue = data.request_data[secondKey];
    return { key: secondKey, value: secondValue };
  }

  const SecondKeyValue: React.FC<{ data: BoardModel }> = (data: any) => {
    const entries = Object.entries(data);

    return (
      <>
        {entries.map(([key, value]) => (
          <div key={key} style={{ borderBottom: "1px solid #EEEEEE" }}>
            <div style={{ fontSize: 20, fontWeight: "bold" }}>{key}</div>
            <div style={{ fontSize: 18 }}>{JSONViewer(value)}</div>
          </div>
        ))}
      </>
    );
  };

  const JSONViewer: React.FC<{ data: any }> = (data: any) => {
    if (
      typeof data === "string" ||
      typeof data === "number" ||
      typeof data === "boolean"
    ) {
      if (typeof data === "string" && data.indexOf("http") > -1) {
        return (
          <a href={data} target="_blank">
            Link
          </a>
        );
      }
      return <span className="json-value">{String(data)}</span>;
    } else if (Array.isArray(data)) {
      return (
        <ul className="json-array">
          {data.map((item, index) => {
            {
            }
            return (
              //{<JSONViewer data={item} />}
              <li key={index}></li>
            );
          })}
        </ul>
      );
    } else if (typeof data === "object" && data !== null) {
      return (
        <div className="json-object">
          {Object.entries(data).map(([key, value]) => {
            return (
              <div key={key} className="json-pair">
                <span className="json-key">{key}: </span>
                <span>
                  {Array.isArray(value) && value[0].indexOf("http") > -1 ? (
                    <a href={value[0]} target="_blank">
                      Link
                    </a>
                  ) : (
                    value
                  )}
                </span>
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <section
      style={{
        maxWidth: 916,
        wordWrap: "break-word",
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
      }}
      className="content"
      id="qna_list"
    >
      <h3>용역 게시판</h3>
      <div className="round-box">
        <div className="col-12">
          <div className="board-content" id="qna_board_detail">
            <div className="title">{getSecondKeyValue(contact).key}</div>
            <div className="author">
              <div>
                작성일
                <span className="ms-2">
                  {new Date(contact?.created_at!).format("yyyy-MM-dd HH:mm:ss")}
                </span>
              </div>
            </div>
            <div className="py-4 border-bottom">
              {contact?.request_data
                ? SecondKeyValue(contact.request_data)
                : ""}
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center mt-5">
                <div>
                  <Link
                    to={pages.contact}
                    className="btn btn-lg btn-primary-soft"
                  >
                    <span className="material-symbols-outlined me-2">edit</span>
                    문의하기
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
